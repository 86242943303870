/* eslint-disable react-hooks/rules-of-hooks */
import './GuestModal.css';
import { addDoc, collection, getDocs, query, Timestamp, where } from 'firebase/firestore';
import React, { useState } from 'react';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import 'react-intl-tel-input/dist/main.css';

function GuestModal({ isOpen, toggleGuestModal, invitationNumber, event }) {
  if (!isOpen) return null;

  const navigate = useNavigate();
  const [firstname, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');

  const checkIfEmailExists = async (email) => {
    const emailQuery = query(collection(db, event.eventName + event.session_id), where("email", "==", email));
    const emailSnapshot = await getDocs(emailQuery);
    return !emailSnapshot.empty;
  };

  const sendMailzeetEmail = async (newTicketCode) => {

    const qrCodeUrl = `https://quickchart.io/qr?text=${newTicketCode}`;

    const url = "https://api.mailzeet.com/v1/mails";
    const payload = {
      sender: {
        name: "WEAREBIAM"
      },
      recipients: [
        {
          email: email,
          name: firstname
        }
      ],
      subject: "Voici votre invitation pour " + event.eventName + " - " + event.sessionName,
      template_id: "aknk2zqw8tek",
      params: {
        company: "WEAREBIAM",
        coverUrl: event.mailCoverUrl,
        eventName: event.eventName,
        sessionName: event.sessionName,
        invitationNumber: invitationNumber,
        firstname: firstname,
        email: email,
        startTime: event.startTime,
        endTime: event.endTime,
        ticketId: newTicketCode,
        qrCodeUrl: qrCodeUrl,
        ticketQuantity: '01',
        formule: "Guest",

      }
    };

    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer 00fz4or70vxd:01JC0ZF8J11JT7S8CKKR0VFCSJ" // Remplace par ta clé API Mailzeet
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      console.log("Email sent successfully:", data);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setEmailError(''); // Reset email error

    try {
      const emailExists = await checkIfEmailExists(email);

      if (emailExists) {
        setEmailError('Cette adresse email a déjà effectué une réservation !');
        return false; // Indique que l'opération a échoué
      }

      const newTicketCode = crypto.randomUUID().replace(/-/g, '').substr(0, 12);

      // Ajoute les données du formulaire dans la collection Firestore

      await addDoc(collection(db, event.eventName + event.session_id), {
        id: invitationNumber,
        firstname: firstname.toLowerCase(),
        email: email,
        sentAt: Timestamp.now(),
        scannedAt: null,
        ticketId: newTicketCode,
        formule: "Guest",
        status: true,
        ticketQuantity: 1,

      });

      // Réinitialiser l'input et les états après le succès
      setFirstName('');
      setEmail('');

      toggleGuestModal();
      navigate('/success', {
        state: {
          successMessage: "Merci pour votre réservation !",
          successSubject: "le ticket n’est pas livrée",
        },
      });

      await sendMailzeetEmail(newTicketCode); // Envoi l'email avec le bon ticketCode

      return true;

    } catch (error) {
      setEmailError('Une erreur est survenue lors de l\'inscription. Veuillez réessayer.');
      console.error("Erreur lors de l'envoi des données vers Firestore : ", error);
      return false; // Indique que l'opération a échoué
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="relative w-full overflow-y-scroll">
      {/* Overlay avec transition en opacity */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-30 transition-opacity duration-500 ease-in-out"
          onClick={toggleGuestModal} // Ferme le sidenav si on clique en dehors
        ></div>
      )}

      {/* Le sidenav qui sort du côté droit avec transition */}
      <div
        className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:w-[600px] w-full bg-bloody text-black rounded-[20px] overflow-y-scroll ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
          } transition-all duration-500 ease-in-out z-40`}
      >

        <div className="flex flex-col lg:gap-3 gap-5 lg:p-10 px-[25px] py-[50px] overflow-y-scroll lg:h-[unset] h-[99dvh]">
          <div className="flex items-start justify-between font-bold">
            <p className="text-[42px] text-white font-extralight ml-2 w-fit uppercase">Vos informations</p>

            <div onClick={toggleGuestModal} className="flex justify-center items-center rounded-full text-[24px] bg-black text-wheat active:bg-white active:text-bloody p-1 h-10 w-10 cursor-pointer">&times;</div>
          </div>

          <div className="flex items-start justify-start">
            <p className="text-[12px] ml-2 text-white uppercase ppmori">Veuillez renseigner les informations pour la réservation.</p>
          </div>

          <form className="flex flex-col lg:gap-[20px] gap-[25px] lg:mt-2 mt-5" onSubmit={handleSubmit}>
            <input type="hidden" id="session" name="session" value={event.session_id} />
            <input type="hidden" id="coverUrl" name="coverUrl" value={event.coverUrl} />
            <input type="hidden" id="date" name="date" value={event.date} />
            <input type="hidden" id="month" name="month" value={event.month} />

            <input
              className="flex justify-center bg-black text-white font-light lg:text-[24px] text-[16px] bg-darksky rounded-full lg:px-[45px] lg:py-[15px] px-[30px] py-5 focus:outline-none"
              placeholder="PRENOM"
              type="text"
              name="firstname"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />

            <input
              className="flex justify-center bg-black text-white font-light lg:text-[24px] text-[16px] bg-darksky rounded-full lg:px-[45px] lg:py-[15px] px-[30px] py-5 focus:outline-none"
              placeholder="EMAIL"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {emailError && <p className="text-white text-center font-light lg:text-[20px] text-[14px] ml-2">{emailError}</p>}

            {/* <p className="lg:text-[12px] text-center text-white text-[12px] mt-5 uppercase ppmori">L'invitation est Valable pour 2</p> */}


            <div className="flex justify-end items-center mt-10">
              <button
                className="flex justify-center bg-black text-white active:bg-white active:text-bloody font-bold lg:text-[24px] text-[14px] bg-darksky rounded-full w-full lg:px-[45px] lg:py-[15px] px-[30px] py-5 select-none"
                type="submit">
                {isLoading ? (
                  <div className="w-5 h-5 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                ) : (
                  "ENVOYER"
                )}
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

export default GuestModal;