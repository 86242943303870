import React from 'react';
import logo from '../../assets/images/logo/lim_logo_white.svg';
import itsok from '../../assets/icons/check_circle.svg';
import { Link, useLocation } from 'react-router-dom';

function Success() {
    
    const location = useLocation();
    const { successMessage, successSubject } = location.state || {};

    return (
        <div className='flex justify-center ppmori items-center min-h-[100vh] w-full bg-bloody'>
            <div className="flex items-center text-center flex-col gap-[30px] px-5">
                <img
                    src={logo}
                    alt="BIAM"
                    className="max-w-[200px] lg:max-w-[300px]"
                />
                <img src={itsok} alt=""></img>

                <p className="flex gap-2.5 flex-col text-white">
                    <strong className='text-[20px]'>{successMessage}</strong>
                    <span>Veuillez vérifiez votre boîte mail. Si {successSubject}, n’hésitez pas à nous contacter.</span>
                </p>

                <Link className="px-5 py-4 bg-black text-white font-bold w-fit rounded-[5px]" to={"/"}>Home</Link>
            </div>
        </div>
    )
}

export default Success