import './home.css';
import React, { useEffect, useState } from 'react';
import lim_est_2024 from '../../assets/images/logo/lostinmelo_est_2024_white.svg';
import limlogo from '../../assets/images/logo/lim_logo_white.svg';
import Newsletter from '../Newsletter/Newsletter';
import { Link } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import GuestModal from '../GuestModal/GuestModal';

function Home() {

    const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
    const [selectedInvitNumber, setSelectedInvitNumber] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState([]);

    function toggleGuestModal(invitnumber, party) {
        setSelectedEvent(party)
        setIsGuestModalOpen(!isGuestModalOpen);
        setSelectedInvitNumber(invitnumber);
    }

    function handleInvitation(invitnumber, party) {
        // Mise à jour de la session et ouverture du modal
        toggleGuestModal(invitnumber, party);
    }

    const [invitationsCount, setInvitationsCount] = useState({});

    useEffect(() => {
        const unsubscribers = dayParties.map((session) => {
            return onSnapshot(collection(db, session.eventName + session.session_id), (snapshot) => {
                setInvitationsCount((prev) => ({
                    ...prev,
                    [session.session_id]: snapshot.size + 1, // Ajoute 1 pour commencer à 1 au lieu de 0
                }));
            });
        });

        return () => {
            unsubscribers.forEach((unsubscribe) => unsubscribe());
        };
    }, []);



    const dayParties = [
        {
            eventName: "Lost In Melo",
            session: "SESSION 06",
            session_id: 6,
            sessionName: "Tanbou A Sonnen",
            genre: "Le Tambour a sonné - SPECIAL KOMPA EDITION",
            featuring: "",
            description: "Laissez-vous emporter par des mélodies cadencées, des cuivres flamboyants et des percussions ensorcelantes. Une immersion envoûtante dans l’énergie irrésistible du Kompa !",
            location: "La Villa****",
            location_maps: "https://maps.app.goo.gl/ek6fiGLpephXAG786",
            date: "30",
            month: "MARS",
            startTime: "15",
            endTime: "22",
            coverUrl: "https://firebasestorage.googleapis.com/v0/b/lost-in-melo.appspot.com/o/session-6-square.jpg?alt=media&token=b0924303-cee9-473b-b150-cdf03e6e0dbb",
            mailCoverUrl: "https://firebasestorage.googleapis.com/v0/b/lost-in-melo.appspot.com/o/session-6-square.jpg?alt=media&token=b0924303-cee9-473b-b150-cdf03e6e0dbb",
            status: 0,
            maxInvitations: 30,
        },

    ]

    return (
        <body className="flex flex-col items-center bg-bloody min-h-[100dvh]">

            <div className="flex flex-col items-center justify-center lg:h-[100dvh] gap-[30px] lg:px-[30px] lg:m-0 px-5 w-full">

                <img className='xl:my-[30px] my-[30px] max-w-[200px] lg:max-w-[300px]' src={limlogo} alt="" />

                {dayParties.map((session, index) => (

                    <div key={index} className="lg:flex items-center grid grid-cols-1 rounded-[20px] lg:h-[600px] lg:max-w-[1200px] max-w-[500px]">

                        <div style={{ backgroundImage: `url(${session.coverUrl})` }}
                            className="flex items-center justify-center bg-black border-white lg:bg-cover bg-no-repeat bg-center border-2 lg:w-2/5 w-full h-full lg:rounded-l-[20px] lg:rounded-tr-none rounded-t-[20px] lg:px-32 lg:py-20">
                            <img className="lg:hidden rounded-t-[20px]" src={session.coverUrl} alt="" />
                        </div>

                        <div
                            className="flex flex-col items-center justify-center border-white lg:border-l-0 lg:border-t-2 lg:border-r-2 lg:border-b-2 border-l-2 border-r-2 border-b-2 border-t-0 lg:w-3/5 h-full lg:rounded-r-[20px] lg:rounded-b-none rounded-b-[20px] lg:px-[50px] px-[30px] lg:py-[unset] py-[30px] gap-10">

                            <div className="flex flex-col text-wheat">

                                <p className=" uppercase font-extrabold lg:text-[60px] text-[40px]">{session.sessionName}</p>
                                <p className=" uppercase font-extrabold lg:text-[16px] text-[12px] ppmori">{session.genre}</p>

                                <div className="flex flex-col mt-10">
                                    <p className="font-light lg:text-[32px] text-[14px] uppercase">{session.session} {session.featuring} : {session.date} {session.month} | {session.startTime}H-{session.endTime}H</p>
                                    <a href={session.location_maps} className="location" target="_blank"
                                        rel="noopener noreferrer">
                                        <p className="font-light lg:text-[32px] text-[12px]">{session.location}</p>
                                    </a>

                                    <p className="font-light lg:text-[20px] text-[14px] ppmori uppercase text-justify">{session.description}</p>
                                </div>
                            </div>

                            <div className="flex flex-col w-full lg:gap-5 gap-[15px] lg:pb-0 pb-10" id="dayparties">

                                <div className="flex lg:flex-row flex-col gap-5 justify-between">

                                    <div className="flex flex-col items-center gap-1">

                                        <button
                                            onClick={() => handleInvitation(invitationsCount[session.session_id], session)}
                                            className="text-black bg-white lg:text-[32px] text-[18px] font-bold py-2.5 px-10 rounded-full reserve lg:w-[230px] w-[180px] cursor-pointer"
                                        >
                                            RESERVATION
                                        </button>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                ))}

            </div>

            <Newsletter />

            <footer className="flex justify-center w-full bg-bloody border-t-2 border-black mt-[70px]">

                <div
                    className="flex flex-col justify-center items-center gap-[50px] lg:mt-[50px] mt-5 lg:mb-10 mb-[50px] lg:max-w-[1024px] w-full ">

                    <img src={lim_est_2024} alt="" />

                    <div className="flex justify-center items-center w-full mb-[10]">
                        <a className="text-white font-light lg:text-[72px] text-[34px] text-center"
                            href="https://www.instagram.com/lostinmelo/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
                    </div>

                    <Link to={"/faq"} className="lg:text-[20px] text-[14px] font-light text-white lg:-rotate-90 lg:absolute lg:left-20 lg:mb-10 -mb-10">
                        FAQ</Link>

                    <p className="lg:text-[24px] text-[14px] font-light text-white lg:-rotate-90 lg:absolute lg:right-0 lg:mb-10">© 2024
                        LOST IN MELO -
                        LÉGAL</p>

                </div>

            </footer>

            <GuestModal
                isOpen={isGuestModalOpen}
                closeSidenav={() => setIsGuestModalOpen(false)}
                invitationNumber={selectedInvitNumber}
                toggleGuestModal={toggleGuestModal}
                event={selectedEvent}
            />

        </body>

    )
}

export default Home