import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/Home/Home';
import Faq from './components/FAQ/Faq';
import ScrollToTop from './components/ScrolltoTop/ScrollToTop';
import Success from './components/Success/Success';


const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/success" element={<Success />} />

        <Route path="/faq" element={<Faq />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

