// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBC3Rks8my96VVxX_WI3apsgTxUecTjbZI",
  authDomain: "lost-in-melo.firebaseapp.com",
  projectId: "lost-in-melo",
  storageBucket: "lost-in-melo.appspot.com",
  messagingSenderId: "198853261332",
  appId: "1:198853261332:web:16bfe2705851bb04c38f01",
  measurementId: "G-9161SLHPB9"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

export { db, functions };