import React, { useState } from 'react';
import limlogo from '../../assets/images/logo/lostinmelo_est_2024_white.svg';
import Newsletter from '../Newsletter/Newsletter';
import { Link } from 'react-router-dom';
import lim_est_2024 from '../../assets/images/logo/lim_est_2024.svg';


const Faq = () => {

    const [openIndex, setOpenIndex] = useState(null); // Gère quelle réponse est ouverte

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Ouvre/ferme les réponses
    };

    const faqData = [
        {
            question: "C'EST QUAND LA PROCHAINE EDITION ?",
            answer: "La prochaine édition de Lost In Melo se tiendra le Dimanche 30 Mars 2025, à La Villa***",
        },
        {
            question: "QUELS SONT LES TARIFS D'ENTRÉES ?",
            answer: "L'entrée est libre mais soumise à une réservation préalable.",
        },
        // {
        //     question: "QUELLE EST LA DIFFéRENCE ENTRE LES PRIX DE TICKETS ?",
        //     answer: "Il n'y a aucune différence entre les différents niveaux de tickets, si ce n'est le prix. Plus le jour de l'évènement s'approche, plus le prix augmente. Par conséquent, plus tôt vous achetez votre billet, plus vous économisez et vous nous permettez de bien nous organiser pour mieux vous acceuillir !",
        // },
        {
            question: "PUIS-JE FAIRE DES ALLER-RETOURS ?",
            answer: "Oui, vous pourrez faire des aller-retours en prenant soin de votre bracelet qui vous permet d'accéder au site de l'événement à tout moment.",
        },
        {
            question: "RESTRICTIONS D'AGE ?",
            answer: "lost in melo est un événement réservé à un public de plus de 18 ans. L'organisateur se réserve le droit de réclamer une pièce d'identification à tout participant. Tout participant ne respectant pas cette restriction, ou qui en outre aurait enfreint cette restriction et contourné les contrôles se fera expulsé sans préavis. La vente d'alcool est exclusivement réservée au public en âge légal de consommer. (+18ans)",
        },
        {
            question: "LES INTERDITS",
            answer: "Aucune nourriture ou boisson provenant de l'extérieur n'est autorisée sur le site de l'évènement.•En plus des éléments interdits ailleurs dans les Termes & Conditions, sans l'autorisation écrite préalable expresse du producteur de l'événement, l'utilisateur ne peut apporter aucun des éléments suivants dans l'événement (ou faire entrer l'un des éléments dans l'événement): bouteilles en verre, bouteilles en aluminium, couteaux, explosifs, lance-pierres, armes de tout autre type, frisbees, drapeaux, planches à roulettes, patins à roulettes, hoverboards, pointeurs laser, drones de tout type, dispositifs télécommandés ou jouets télécommandés, grands sacs à dos et autres articles tels que désigné par le producteur de l'événement. Le producteur de l'événement se réserve le droit de refuser l'admission ou d'expulser toute personne, dont la conduite est désordonnée, perturbatrice ou qui ne respecte pas les règles ou les instructions de l'événement, ou dont le langage est vulgaire ou abusif.",
        },
        {
            question: "EXPULSION EN CAS DE",
            answer: "• Consommation irresponsable ou dangereuse d'alcool.• Accès aux zones d'accès restreint sans les autorisations nécessaires.• Moquerie, langage abusif ou offensant.• Gestes obscènes ou offensants, y compris l'utilisation de gestes obscènes ou offensants par le biais de l'imagerie ou du symbolisme (y compris les bannières, les signes et autres moyens de propagande visuelle).• Possession / utilisation/vente de substances illégales.• Uriner ou déféquer en public (en dehors de toilettes appropriées et/ou publiques).• Se battre ou s'engager dans toute action susceptible de nuire, de mettre en danger, de menacer ou d'incommoder quelqu'un. Zéro tolérance à toute forme de violence.• Dommage, destruction, vandalisme ou vol de tout bien.• Participer à toute activité qui humilie une personne ou insulte sa dignité humaine.",
        },
        // {
        //     question: "PUIS-JE ACHETER DES TICKETS À L'ENTRÉE?",
        //     answer: "Oui, vous pourrez acheter des tickets à l'entrée à 5.000 XOF.",
        // },
        // {
        //     question: "Happy Hour",
        //     answer: "LaVilla & Saa Komi vous réserve un spécial Happy Hour de 16h à 18h avec des shots et des tapas gratuits à déguster et savourer. Venez tôt pour en profiter.",
        // },
    ];


    return (
        <div className="flex w-full h-full bg-bloody">

            <div className='flex flex-col justify-center items-center min-h-[100vh] h-full w-full bg-bloody'>
                <Link to={"/"}>
                    <img className='lg:my-[80px] my-[40px] max-w-[150px] lg:max-w-[300px]' src={limlogo} alt="" />
                </Link>


                <div className="flex flex-col justify-center w-full lg:gap-10 gap-5">
                    <p className="lg:text-[65px] text-[36px] text-center text-white">FAQ</p>

                    <div className="flex flex-col w-full justify-center items-center lg:gap-10 gap-5 ppmori">
                        {faqData.map((item, index) => (
                            <div key={index} className=" lg:h-fit lg:border-[6px] lg:max-w-[800px] max-w-[300px] w-full border-[4px] border-white lg:rounded-[20px] rounded-[15px]">

                                <div className="flex justify-between items-center p-4 cursor-pointer" onClick={() => toggleDropdown(index)}>
                                    <p className="lg:text-[24px] text-white">{item.question}</p>
                                    <button className="text-[24px] text-white">
                                        {openIndex === index ? '-' : '+'}
                                    </button>
                                </div>

                                {openIndex === index && (
                                    <div className="p-4 border-t-2 border-offblack bg-white transition-all duration-300 ease-in-out">
                                        {item.answer.includes("•") ? (
                                            item.answer.split("•").map((line, i) =>
                                                line.trim() ? (
                                                    <p key={i} className="lg:text-[20px] text-black">
                                                        • {line.trim()}
                                                    </p>
                                                ) : null
                                            )
                                        ) : (
                                            <p className="lg:text-[20px] text-black">{item.answer}</p>
                                        )}
                                    </div>
                                )}


                            </div>
                        ))}
                    </div>

                </div>

                <Newsletter />

                <footer className="flex justify-center w-full border-t-2 border-black mt-[70px]">

                    <div
                        className="flex flex-col justify-center items-center gap-[50px] lg:mt-[50px] mt-5 lg:mb-10 mb-[50px] lg:max-w-[1024px] w-full ">

                        <img src={limlogo} alt="" />

                        <div className="flex justify-center items-center w-full mb-[10]">
                            <a className="text-white font-light lg:text-[72px] text-[34px] text-center"
                                href="https://www.instagram.com/lostinmelo/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
                        </div>

                        <Link to={"/faq"} className="lg:text-[20px] text-[14px] font-light text-white lg:-rotate-90 lg:absolute lg:left-0 lg:ml-20 lg:mb-10 -mb-10">
                            FAQ</Link>

                        <p className="lg:text-[20px] text-[14px] font-light text-white lg:-rotate-90 lg:absolute lg:right-0 lg:mb-10">© 2024
                            LOST IN MELO -
                            LÉGAL</p>

                    </div>

                </footer>

            </div>
        </div>
    )
}

export default Faq