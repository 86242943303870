import React, { useState } from 'react'
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../firebase';

function Newsletter() {

    const [email, setEmail] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [emailError, setEmailError] = useState('');

    const checkIfEmailExists = async (email) => {
        const emailQuery = query(collection(db, "newsletter"), where("email", "==", email));
        const emailSnapshot = await getDocs(emailQuery);
        return !emailSnapshot.empty;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setEmailError(''); // Reset email error
        setSuccessMessage(''); // Reset success message

        try {
            const emailExists = await checkIfEmailExists(email);

            if (emailExists) {
                setEmailError('Tu es déjà abonné(e) à notre newsletter.');
                setIsLoading(false);
                return; // Sortie anticipée si l'email existe déjà
            }

            // Ajout de l'email à la collection "newsletter" dans Firestore
            await addDoc(collection(db, 'newsletter'), {
                email: email,
                addedAt: new Date(),
            });

            // Réinitialiser l'input et les états après le succès
            setEmail('');
            setIsLoading(false);
            setIsSuccess(true);
            setSuccessMessage('Tu es maintenant abonné(e) à notre newsletter.');

        } catch (error) {
            setIsLoading(false);
            setEmailError('Une erreur est survenue lors de l\'inscription. Veuillez réessayer.');
            console.error('Erreur lors de l\'enregistrement : ', error);
        }
    };


    return (

        <div className="flex flex-col lg:gap-[30px] items-center justify-center lg:mt-[100px] mt-[50px]">
            <p className="text-white font-extrabold lg:text-[52px] text-[34px]">LA NEWSLETTER</p>

            <form onSubmit={handleSubmit} action="" className="flex mt-[30px] mb-5">
                <input
                    className="flex justify-center text-wheat font-light lg:text-[24px] text-[16px] bg-black border-wheat border-2 border-r-0 rounded-l-full lg:px-[45px] lg:py-10 px-[30px] py-5 focus:outline-none"
                    placeholder="Ton email" type="email" name="email" id="" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <button
                    className="flex justify-center text-wheat font-bold lg:text-[24px] text-[16px] bg-black border-wheat border-2 border-l-0 rounded-r-full lg:px-[45px] lg:py-10 px-[30px] py-5 select-none"
                    type="submit">
                    {isLoading ? (
                        <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    ) : (
                        "OK"
                    )}
                </button>
            </form>

            {emailError && <p className="text-white font-light lg:text-[20px] text-[14px] lg:w-[420px] w-[300px] text-center">{emailError}</p>}

            {isSuccess && <p className="text-white font-light lg:text-[20px] text-[14px] lg:w-[420px] w-[300px] text-center">{successMessage}</p>}

            <p className="text-white font-light lg:text-[20px] text-[14px] lg:w-[420px] w-[300px] text-center">
                Tu peux te désinscrire à tout moment.
            </p>

        </div>

    )
}

export default Newsletter